import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as dataManager from '../ContractsInteraction/dataManager.js';
import { faCopy, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard, getShortString } from '../ContractsInteraction/utils/utils.js';
import { IconButton } from '../Components/IconButton.js';
import ProfileModal from '../Components/ProfileModal';
const scanUrl = process.env.REACT_APP_SCAN_URL;


function DataManager() {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [totalUsers, setTotalUsers] = useState(-1);
    const [initialFetch, setInitialFetch] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null); // State to track selected user
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false); // State to manage modal visibility
    const [loadingAll, setLoadingAll] = useState(false); // State to manage loading all users process
    const navigate = useNavigate();
    const limit = 10;
    // Fetch paginated users data
    const fetchPaginatedUsers = async (maxLimit) => {
        if (loading || !hasMore) return;
        setLoading(true);
        try {
            const { users: newUsers, total } = await dataManager.getPaginatedSorted(offset, maxLimit);
            if (newUsers.length < maxLimit) {
                setHasMore(false);
            }
            setTotalUsers(total);
            setUsers((prevUsers) => [...prevUsers, ...newUsers]);
            setOffset((prevOffset) => prevOffset + maxLimit);
        } catch (err) {
            console.error('Failed to fetch paginated users:', err);
        } finally {
            setLoading(false);
        }
    };

    // Fetch users continuously until no more users are available
    const loadAllUsers = async () => {
        if (loadingAll) return; // Prevent triggering multiple simultaneous "Load All" processes
        setLoadingAll(true);
        await fetchPaginatedUsers(totalUsers - offset);
        setLoadingAll(false);
    };

    // Initial data fetch
    useEffect(() => {
        const initialFetchData = async () => {
            setLoading(true);
            try {
                const { users: newUsers, total: totalUsers } = await dataManager.getPaginatedSorted(offset, limit);
                if (newUsers.length < limit) {
                    setHasMore(false);
                }
                setTotalUsers(totalUsers);
                setUsers((prevUsers) => [...prevUsers, ...newUsers]);
                setOffset((prevOffset) => prevOffset + limit);
            } catch (err) {
                console.error('Failed to fetch paginated users:', err);
            } finally {
                setLoading(false);
            }
        };

        if (!initialFetch) {
            initialFetchData();
            setInitialFetch(true);
        }
    }, [initialFetch, offset]);

    const openProfileModal = (userId) => {
        setSelectedUserId(userId);
        console.log(`Opening ${userId} Profile`);
        setIsProfileModalOpen(true);
    };

    const closeProfileModal = () => {
        setIsProfileModalOpen(false);
        setSelectedUserId(null);
    };

    return (
        <div className="user-profile">
            <div className="header">
                <h1>{true ? 'Access Users' : 'Leaderboard'}</h1>
                <h3>Total users: {totalUsers}</h3>
                <button onClick={() => navigate('/')}>Back to Home</button>
            </div>
            <div className="table-container">
                <table className="profile-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Account</th>
                            <th>User ID</th>
                            <th>User Name</th>
                            <th>Total XP</th>
                            <th>Referral XP</th>
                            <th>Referees</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={index}>
                                <td style={{minWidth:'30px'}} className="label">{index + 1}</td>
                                <td className="value">
                                    <IconButton icon={faCopy} onClick={() => copyToClipboard(user.accountAddress)} />
                                    <IconButton icon={faExternalLink} onClick={() => window.open(`${scanUrl}address/${user.accountAddress}`, '_blank')} />
                                    {getShortString(user.accountAddress)}
                                </td>
                                <td className="value">
                                    <IconButton icon={faCopy} onClick={() => copyToClipboard(user.userId)} />
                                    <IconButton icon={faExternalLink} onClick={() => openProfileModal(user.userId)} />
                                    {getShortString(user.userId)}
                                </td>
                                <td className="value">
                                    <IconButton icon={faCopy} onClick={() => copyToClipboard(user.userName)} />
                                    {user.userName}
                                </td>
                                <td className="value">{user.totalXP.toString()}</td>
                                <td className="value">{user.referralXP.toString()}</td>
                                <td className="value">{user.refereesCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {loading && <p>Loading...</p>}
                {!hasMore && <p>No more users to load.</p>}
                {hasMore && !loading && (
                    <>
                        <button onClick={()=>{fetchPaginatedUsers(limit);}}>Load More</button>
                        <button style={{margin:'auto'}} onClick={loadAllUsers}>Load All</button>
                    </>
                )}
            </div>
            {/* Profile Modal */}
            {isProfileModalOpen && (
                <ProfileModal
                    userAddress={selectedUserId}
                    isOpen={isProfileModalOpen}
                    onClose={closeProfileModal}
                />
            )}
        </div>
    );
}

export default DataManager;
