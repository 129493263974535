import { contracts } from "./contracts";
import { relayServer } from "./utils/strings";

/**
 * Retrieve paginated user data as structured objects
 * @param {number} offset - Starting index for pagination
 * @param {number} limit - Number of users to retrieve
 * @returns {Promise<{ users: object[], total: number }>} - Paginated user data and total count
 */
export const getPaginatedUsersDataFromContract = async (offset, limit) => {
    try {
        const { users, total } = await contracts.dataManager.getPaginatedUsersData(offset, limit);
        return { users, total };
    } catch (error) {
        console.error("Error fetching paginated users data:", error);
        return { users: [], total: 0 };
    }
};

/**
 * Retrieve paginated user data as structured objects from the server
 * @param {number} offset - Starting index for pagination
 * @param {number} limit - Number of users to retrieve
 * @returns {Promise<{ users: object[], total: number }>} - Paginated user data and total count
 */
export const getPaginatedSorted = async (offset, limit) => {
    try {
        // Fetch paginated data from the server
        const response = await fetch(`${relayServer}/getPaginatedSorted?offset=${offset}&limit=${limit}`);
        if (!response.ok) {
            throw new Error(`Server error: ${response.status}`);
        }

        const { users, total } = await response.json();
        return { users, total };
    } catch (error) {
        console.error("Error fetching paginated users data:", error);
        return { users: [], total: 0 };
    }
};