import { accountAddress } from "./account";
import { contracts } from "./contracts";

/**
 * Retrieves the balance of tokens for the provided address.
 * @param {string} [address=accountAddress] - The address to fetch the token balance for.
 * @returns {Promise<number|null>} - The token balance as an integer, or null on error.
 */
const balanceOf = async (address = accountAddress) => {
    try {
        return parseInt(await contracts.oli.balanceOf(address));
    } catch (e) {
        console.error('ERROR:', e);
        return null;
    }
};

export  { balanceOf as getOliBalance}
