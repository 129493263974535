import Web3 from 'web3';
import { xpAddress } from './utils/strings';

const rpcUrl = 'https://oli.accesscan.io';
const web3 = new Web3(new Web3.providers.HttpProvider(rpcUrl));
const START_BLOCK = 1116600;


export const getUserTransactionsViaLogs = async (userAddress) => {
    if (!web3.utils.isAddress(userAddress)) {
        throw new Error('Invalid Ethereum address');
    }

    const eventSignature = web3.utils.keccak256('XPAdded(address,uint256,address)'); // Correct event signature
    console.log('Event signature:', eventSignature);

    try {
        const logs = await web3.eth.getPastLogs({
            fromBlock: web3.utils.toHex(START_BLOCK),
            toBlock: 'latest',
            address: xpAddress, // Contract emitting the event
            topics: [
                eventSignature, // Event signature
                web3.utils.padLeft(userAddress.toLowerCase(), 64), // Padded user address
            ],
        });

        console.log('Logs fetched:', logs);

        // Map logs to transactions
        const transactions = logs.map((log) => {
            const data = log.data.slice(2); // Remove '0x' prefix
            const amount = parseInt(data.slice(0, 64), 16); // Decode amount from data
            const modal = `0x${log.topics[2].slice(26)}`; // Decode modal from topics[2]
            return {
                hash: log.transactionHash,
                from: log.address,
                amount: parseInt(amount, 10), // Convert amount to integer
                modal,
                block: Number(log.blockNumber), // Convert BigInt to Number
                timestamp: null, // Placeholder for timestamp
            };
        });

        // // Fetch block timestamps for each transaction
        // for (const tx of transactions) {
        //     console.log('Fetching timestamp for block:', tx.block);
        //     const block = await web3.eth.getBlock(tx.block.toString()); // Ensure block number is passed as a string
        //     if (block && block.timestamp) {
        //         tx.timestamp = new Date(Number(block.timestamp) * 1000).toISOString(); // Convert to ISO string
        //     } else {
        //         console.warn(`Block ${tx.block} not found or missing timestamp.`);
        //     }
        // }

        console.log('Final transactions:', transactions);
        return transactions;
    } catch (error) {
        console.error('Error fetching user transactions:', error.message);
        throw error;
    }
};



// const BATCH_SIZE = 10000; // Number of blocks to process in a batch

// const getMethodSignature = (inputData) => {
//     if (inputData && inputData.length >= 10) {
//         return inputData.slice(0, 10);
//     }
//     return null;
// };

// const decodeAddXPInput = (inputData) => {
//     const parameters = inputData.slice(10);
//     const recipient = '0x' + parameters.slice(24, 64);
//     const amount = parseInt(parameters.slice(64, 128), 16);
//     return { recipient, amount };
// };

// export const getUserTransactions = async (userAddress) => {
//     const latestBlock = parseInt(await web3.eth.getBlockNumber());
//     console.log(latestBlock);
//     const transactions = [];

//     console.log(`Scanning from block ${START_BLOCK} to ${latestBlock}`);
//     console.log(`Looking for XP received by address: ${userAddress}`);

//     for (let startBlock = START_BLOCK; startBlock <= latestBlock; startBlock += BATCH_SIZE) {
//         const endBlock = Math.min(startBlock + BATCH_SIZE - 1, latestBlock);
//         console.log(`Processing blocks ${startBlock} to ${endBlock}`);

//         // Fetch blocks in parallel
//         const batchPromises = [];
//         for (let blockNumber = startBlock; blockNumber <= endBlock; blockNumber++) {
//             batchPromises.push(web3.eth.getBlock(blockNumber, true));
//         }

//         try {
//             const blocks = await Promise.all(batchPromises);

//             blocks.forEach((block) => {
//                 if (block && block.transactions) {
//                     block.transactions.forEach((tx) => {
//                         if (tx.to && tx.to.toLowerCase() === xpAddress.toLowerCase()) {
//                             const methodSig = getMethodSignature(tx.input);

//                             if (methodSig === '0xa40ade0a') {
//                                 const { recipient, amount } = decodeAddXPInput(tx.input);

//                                 if (recipient.toLowerCase() === userAddress.toLowerCase()) {
//                                     const txInfo = {
//                                         hash: tx.hash,
//                                         from: tx.from,
//                                         amount,
//                                         block: block.number,
//                                         timestamp: new Date(block.timestamp * 1000).toISOString(),
//                                     };

//                                     transactions.push(txInfo);
//                                     console.log('Found XP transaction:', txInfo);
//                                 }
//                             }
//                         }
//                     });
//                 }
//             });
//         } catch (err) {
//             console.error(`Error processing blocks ${startBlock} to ${endBlock}:`, err.message);
//             continue;
//         }
//     }

//     return transactions;
// };

// window.getUserTransactions = getUserTransactions;
