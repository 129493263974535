import { contracts } from "./contracts";
import { accountAddress } from "./account.js";

/**
 * Retrieves the user level associated with the provided address.
 * @param {string} [address=accountAddress] - The address to fetch the level for.
 * @returns {Promise<number|null>} - The user level or null on error.
 */
const level = async (address = accountAddress) => {
    try {
        return parseInt(await contracts.levels.getUserLevel(address));
    } catch (e) {
        console.error('ERROR:', e);
        return null;
    }
};

/**
 * Retrieves the user badge associated with the provided address.
 * @param {string} [address=accountAddress] - The address to fetch the badge for.
 * @returns {Promise<string|null>} - The user badge or null on error.
 */
const badge = async (address = accountAddress) => {
    try {
        return await contracts.levels.getUserBadge(address);
    } catch (e) {
        console.error('ERROR:', e);
        return "None";
    }
};

/**
 * Retrieves all levels and their associated XP requirements from the blockchain.
 * @returns {Promise<{levelsList: Object | null>} An array of objects where each object maps a level to its XP requirement,
 * or null if an error occurs.
 */
const getAllLevels = async () => {
    try {
        let levelsList = {};
        const [levelNums, xpReqs] = await contracts.levels.getAllLevels();
        levelNums.forEach((level, index) => {
            levelsList[parseInt(level)] = parseInt(xpReqs[index])
        });
        return levelsList;
    } catch (e) {
        console.error('ERROR:', e);
        return null;
    }
};

window.getAllLevels = getAllLevels;
export { level as getLevel, getAllLevels, badge as getBadge};
