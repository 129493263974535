
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import * as profile from '../ContractsInteraction/profile.js';
import { ZeroAddress } from 'ethers';
import { Loading } from '../Components/Loading.js';
import { IconButton } from '../Components/IconButton.js';
import { getShortString, copyToClipboard, formatNumber } from '../ContractsInteraction/utils/utils.js';
import { accountAddressById, accountAddressByName } from '../ContractsInteraction/utils/contractUtils.js';
import { follow, getFollowersCount, getFollowingCount, isFollowing, unfollow } from '../ContractsInteraction/followers.js';
import { getTotalXP } from '../ContractsInteraction/xp.js';
import { getBadge, getLevel } from '../ContractsInteraction/levels.js';
import { getAccesBalance, getTotalAcces } from '../ContractsInteraction/acces.js';
import { getBlacxesBalance, getTotalBlacxes } from '../ContractsInteraction/blacxes.js';
import { getOliBalance } from '../ContractsInteraction/oli.js';
import { accountAddress, getMainWallet, loggedIn } from '../ContractsInteraction/account.js';
import { getBio } from '../ContractsInteraction/bio.js';

function Profile() {
    const [userContractAddress, setUserContractAddress] = useState('');
    const [username, setUsername] = useState('');
    const [nickname, setNickname] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [country, setCountry] = useState('');
    const [bio, setBio] = useState('');
    const [userId, setUserId] = useState(0);
    const [gender, setGender] = useState(1n);
    const [xp, setXp] = useState(0); // XP Counter
    const [level, setLevel] = useState(0);
    const [badge, setBadge] = useState('');
    const [followingCount, setFollowingCount] = useState(0);
    const [followerCount, setFollowerCount] = useState(0);
    const [wallet, setWallet] = useState('');
    const [followingStatus, setFollowingStatus] = useState(false);
    const [totalAcces, setTotalAcces] = useState(0n);
    const [totalBlacxes, setTotalBlacxes] = useState(0n);
    const [accesBalance, setAccesBalance] = useState(0n);
    const [blacxesBalance, setBlacxesBalance] = useState(0n);
    const [oliBalance, setOliBalance] = useState(0n);
    const [isProcessing, setIsProcessing] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const getData = async () => {
            try {
                if (!userContractAddress) {
                    const params = new URLSearchParams(window.location.search);
                    const usernameParam = params.get('username');
                    const userIdParam = params.get('userId');
                    let address = null;
                    if (usernameParam) {
                        setUsername(usernameParam);
                        address = await accountAddressByName(usernameParam)
                        setUserContractAddress(address);
                    } else if (userIdParam) {
                        setUserId(userIdParam)
                        address = await accountAddressById(userIdParam);
                        setUserContractAddress(address);
                    } else {
                        setNotFound(true);
                        setLoading(false);
                    }
                    if (!address) {
                        setNotFound(true);
                        setLoading(false);
                    }
                    return;
                };
                if (!userContractAddress || userContractAddress === ZeroAddress) {
                    setNotFound(true);
                    setLoading(false);
                    return;
                }
                if (accountAddress && userContractAddress.toLocaleLowerCase() === accountAddress.toLocaleLowerCase()) {
                    navigate('/');
                    return;
                }
                setNotFound(false);
                setLoading(false);
                console.log('User Contract Address:', userContractAddress);
                console.log('Retrieving user data...');
                setFollowingStatus(await isFollowing(userContractAddress));
                setUserId(await profile.getUserId(userContractAddress));
                setUsername(await profile.getUsername(userContractAddress));
                setWallet(await getMainWallet(userContractAddress));
                setNickname(await profile.getNickname(userContractAddress) || 'Not-Set');
                setDateOfBirth(await profile.getDateOfBirth(userContractAddress) || 'Not-Set');
                setCountry(await profile.getCountry(userContractAddress) || 'Not-Set');
                setBio(await getBio(userContractAddress) || 'Not-Set');
                setGender(await profile.getGender(userContractAddress));
                setLevel(await getLevel(userContractAddress));
                setBadge(await getBadge(userContractAddress));
                setFollowerCount(await getFollowersCount(userContractAddress));
                setFollowingCount(await getFollowingCount(userContractAddress));
                setXp(await getTotalXP(userContractAddress));
                setTotalAcces(await getTotalAcces(userContractAddress));
                setTotalBlacxes(await getTotalBlacxes(userContractAddress));
                setAccesBalance(await getAccesBalance(userContractAddress));
                setBlacxesBalance(await getBlacxesBalance(userContractAddress));
                setOliBalance(await getOliBalance(userContractAddress));
                setIsProcessing(false);
            } catch (err) {
                console.error('Failed to get user data', err);
                setLoading(false);
            };
        };
        getData();
    }, [userContractAddress, navigate]);

    if (loading) {
        return <Loading/>;
    }

    if (notFound) {
        return (
            <div className="user-profile">
                <h1>{username} Not Found!</h1>
                <button
                    className="reset-button"
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    Back to Home
                </button>
            </div>)
    }

    return (
        <div>
            <div className="user-profile">
                <h1>{username}</h1>
                <table className="profile-table">
                    <tbody>
                        <tr>
                            <td className="label">UserId</td>
                            <td className="value">
                                <IconButton icon={faCopy} onClick={() => copyToClipboard(userId.toString())} />
                                {getShortString(userId.toString(), 6,8)}
                            </td>
                        </tr>
                        <tr>
                            <td className="label">Username</td>
                            <td className="value">
                                <IconButton icon={faCopy} onClick={() => copyToClipboard(username)} />
                                {username}</td>
                        </tr>
                        <tr>
                            <td className="label">User Account</td>
                            <td className="value">
                                <IconButton icon={faCopy} onClick={() => copyToClipboard(userContractAddress)} />
                                {getShortString(userContractAddress, 6, 8)}
                            </td>
                        </tr>
                        <tr>
                            <td className="label">Main Wallet</td>
                            <td className="value">
                                <IconButton icon={faCopy} onClick={() => copyToClipboard(wallet)} />
                                {getShortString(wallet, 6, 8)}
                            </td>
                        </tr>
                        <tr>
                            <td className="label">Nickname</td>
                            <td className="value">{nickname}</td>
                        </tr>
                        <tr>
                            <td className="label">Date Of Birth</td>
                            <td className="value">{dateOfBirth}</td>
                        </tr>
                        <tr>
                            <td className="label">Country</td>
                            <td className="value">{country}</td>
                        </tr>
                        <tr>
                            <td className="label">Bio</td>
                            <td className="value">{bio}</td>
                        </tr>
                        <tr>
                            <td className="label">XP</td>
                            <td className="value">{xp.toString()}</td>
                        </tr>
                        <tr>
                            <td className="label">Level:</td>
                            <td className="value">{level.toString()}</td>
                        </tr>
                        <tr>
                            <td className="label">Badge:</td>
                            <td className="value">{badge.toString()}</td>
                        </tr>
                        <tr>
                            <td className="label">Following:</td>
                            <td className="value">{followingCount}</td>
                        </tr>
                        <tr>
                            <td className="label">Followers:</td>
                            <td className="value">{followerCount}</td>
                        </tr>
                        <tr>
                            <td className="label">Gender:</td>
                            <td className="value">{
                                gender === 1n ? 'Male' : 'Female'
                            }</td>
                        </tr>
                        <tr>
                            <td className="label">Total Acces:</td>
                            <td className="value">{`${formatNumber(totalAcces)} ACCES`}</td>
                        </tr>
                        <tr>
                            <td className="label">Acces Balance:</td>
                            <td className="value">{`${formatNumber(accesBalance)} ACCES`}</td>
                        </tr>
                        <tr>
                            <td className="label">Total Blacxes:</td>
                            <td className="value">{totalBlacxes.toString()}</td>
                        </tr>
                        <tr>
                            <td className="label">Blacxes:</td>
                            <td className="value">{blacxesBalance.toString()}</td>
                        </tr>
                        <tr>
                            <td className="label">Oli Balance:</td>
                            <td className="value">{oliBalance.toString()}</td>
                        </tr>
                    </tbody>
                </table>
                {loggedIn() && (
                    <button
                        onClick={async () => {
                            setIsProcessing(true);
                            if (followingStatus) {
                                await unfollow(userContractAddress);
                            } else {
                                await follow(userContractAddress);
                            }
                            setTimeout(async () => {
                                setFollowerCount(await getFollowersCount(userContractAddress));
                                setFollowingStatus(await isFollowing(userContractAddress));
                                setIsProcessing(false);
                            }, 3000);
                        }}
                        disabled={isProcessing}
                    >
                        {isProcessing ? "Processing..." : (followingStatus ? "Unfollow" : "Follow")}
                    </button>
                )}
                <button
                    className={loggedIn() ? "reset-button" : "margin-top-10px"}
                    onClick={() => {
                        if (loggedIn()) {
                            navigate('/');
                        } else {
                            navigate('/create-import');
                        }
                    }}
                >
                    {loggedIn() ? 'Back to Home' : 'Create or Impore Account'}
                </button>
            </div>
        </div>
    );
}
export default Profile;
