import { contracts } from "./contracts";
import { accountAddress } from "./account";
import { relayServer } from "./utils/strings";

/**
 * Retrieves the total XP for the specified user account.
 * @param {string} [address=accountAddress] - The address to check for XP.
 * @returns {Promise<number|null>} - The total XP or null on error.
 */
const xp = async (address = accountAddress) => {
    try {
        return parseInt(await contracts.xp.getTotalXP(address));
    } catch (e) {
        console.error('ERROR:', e);
        return null;
    }
};

/**
 * Adds XP to the specified user account by calling the relay server.
 * @param {number} xpToAdd - The amount of XP to add.
 * @returns {Promise<void>}
 */
const addXp = async (xpToAdd) => {
    if (!accountAddress || xpToAdd === 0) return;

    try {
        const response = await fetch(`${relayServer}/addXp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: accountAddress,
                xp: xpToAdd
            })
        });

        if (response.ok) {
            const responseData = await response.json();
            console.log(`Added ${xpToAdd} XP with txHash: ${responseData.txHash}`);
        } else {
            const error = await response.json();
            console.error("Error executing transaction:", error["error"]);
        }
    } catch (e) {
        console.error('Error adding XP to contract:', e);
    }
};

/**
 * Retrieves the total earned by referring XP for the specified user account.
 * @param {string} [address=accountAddress] - The address to check for XP.
 * @returns {Promise<number|null>} - The total XP or null on error.
 */
const getReferrerEarnedXP = async (address = accountAddress)=>{
    try {
        console.log(address);
        const xpEarned = await contracts.xp.getReferrerEarnedXP(address);
        console.log(xpEarned);
        return parseInt(xpEarned);
    } catch (e) {
        console.error('ERROR:', e);
        return null;
    }
}

export {
    xp as getTotalXP,
    getReferrerEarnedXP,
    addXp
};
