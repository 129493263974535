import { HDNodeWallet, Wallet, ZeroAddress, isAddress } from 'ethers';
import { accountAddress } from '../account';
import { contracts } from './../contracts';
import { getStoredUser } from './utils';

let legendaryOliAddress = null;
const legendaryOli = async () => {
    if (!legendaryOliAddress)
        legendaryOliAddress = await contracts.userCreator.legendaryOli();
    return legendaryOliAddress;
}

const accountAddressByName = async (username) => {
    try {
        const userAccountAddress = await contracts.userCreator.getUserAccountAddressByName(username);
        return userAccountAddress && userAccountAddress !== ZeroAddress ? userAccountAddress : null;
    } catch (e) {
        console.error("ERRROR:", e);
        return null;
    }
}

const accountAddressById = async (id) => {
    try {
        const userAccountAddress = await contracts.userCreator.getUserAccountAddressById(id);
        return userAccountAddress && userAccountAddress !== ZeroAddress ? userAccountAddress : null;
    } catch (e) {
        console.error(e);
        return null;
    }
}

const userIdByAccountAddress = async (address) => {
    const user = await contracts.userCreator.getUserIdAndUserName(address);
    return user._userId;
}

const usernameByAccountAddress = async (address) => {
    const user = await contracts.userCreator.getUserIdAndUserName(address);
    return user._userName;
}

const getOwnersWallet = (owner, owners) => {
    owner = owner.toLocaleLowerCase();
    if (owner === accountAddress.toLocaleLowerCase())
        return accountAddress;
    var wallet = Wallet.fromPhrase(getStoredUser().mnemonic);
    if (owner === wallet.address.toLocaleLowerCase())
        return wallet;
    const rootNode = HDNodeWallet.fromPhrase(getStoredUser().mnemonic);
    let index = 0;
    let ownersWallet = null;
    do {
        ownersWallet = rootNode.deriveChild(index);
        index++;
        if (index > 500) { //Assuming that there was less than 500 owners
            console.error(`Owner ${owner} not found`);
            return null;
        }
    } while (ownersWallet.address.toLocaleLowerCase() !== owner);
    return ownersWallet;
};

const isDerivedWallet = (address, mnemonic) => {
    var wallet = Wallet.fromPhrase(mnemonic);
    if (address.toLocaleLowerCase() === wallet.address.toLocaleLowerCase())
        return true;

    const rootNode = HDNodeWallet.fromPhrase(mnemonic);
    let index = 0;
    do {
        wallet = rootNode.deriveChild(index);
        index++;
        if (index > 500) { //Assuming that there was less than 500 owners
            console.error(`Wallet with ${address} not found`);
            return false;
        }
    } while (wallet.address.toLocaleLowerCase() !== address.toLocaleLowerCase());
    return true;
}
const generateNewAddress = async (mnemonic, existingAddresses) => {
    const rootNode = HDNodeWallet.fromPhrase(mnemonic);
    let index = 0;
    let newAddress;
    do {
        const childWallet = rootNode.deriveChild(index);
        newAddress = childWallet.address;
        index++;
    } while (existingAddresses.includes(newAddress) || !isAddress(newAddress));
    console.log(`Generated new address: ${newAddress}`);
    return isAddress(newAddress) ? newAddress : null;
}

export {
    legendaryOli,
    accountAddressByName,
    accountAddressById,
    userIdByAccountAddress,
    usernameByAccountAddress,
    getOwnersWallet,
    generateNewAddress,
    isDerivedWallet
}