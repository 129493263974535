import { ZeroAddress } from "ethers";
import { accountAddress, wallet } from "./account";
import { contracts } from "./contracts";
import { getStoredUser } from "./utils/utils";
import { propose } from "./utils/transaction";
import { relayServer } from "./utils/strings";
import { getBadge, getLevel } from "./levels";
import { getReferrerEarnedXP, getTotalXP } from "./xp";
import { getFollowersCount, getFollowingCount, isFollowing } from "./followers";
import { userIdByAccountAddress } from "./utils/contractUtils";
import { getRefereesCount, getReferralsCount } from "./referrals";

/**
 * Fetches the username associated with an account.
 * @param {string} address - The address to fetch username for.
 * @returns {Promise<string|null>} - Username or null if not found.
 */
const username = async (address = accountAddress) => {
    try {
        const username = await contracts.userAccount(address).userName();
        return username && username !== ZeroAddress ? username : null;
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Fetches the user ID.
 * @param {string} address - The address to fetch user ID for.
 * @returns {Promise<string|null>} - User ID or null if not found.
 */
const userId = async (address = accountAddress) => {
    try {
        const userId = await contracts.userAccount(address).userId();
        return userId && userId !== ZeroAddress ? userId : null;
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};
/**
 * Fetches the nickname associated with the account.
 * @param {string} address - The address to fetch nickname for.
 * @returns {Promise<string|null>} - Nickname or null if not found.
 */
const nickname = async (address = accountAddress) => {
    try {
        return await contracts.userAccount(address).nickName();
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Fetches the date of birth associated with the account.
 * @param {string} address - The address to fetch date of birth for.
 * @returns {Promise<string|null>} - Date of birth or null if not found.
 */
const dateOfBirth = async (address = accountAddress) => {
    try {
        return await contracts.userAccount(address).dateOfBirth();
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Fetches the country associated with the account.
 * @param {string} address - The address to fetch country for.
 * @returns {Promise<string|null>} - Country or null if not found.
 */
const country = async (address = accountAddress) => {
    try {
        return await contracts.userAccount(address).country();
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Fetches the gender associated with the account.
 * @param {string} address - The address to fetch gender for.
 * @returns {Promise<number|null>} - Gender or null if not found.
 */
const gender = async (address = accountAddress) => {
    try {
        return parseInt(await contracts.userAccount(address).gender(address));
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Retrieve the rank of a specific user by their user ID
 * @param {string} userId - The ID of the user whose rank is being fetched
 * @returns {Promise<{ rank: number }>} - The user's rank
 */
const getRank = async (address = accountAddress) => {
    try {
        const id = await userIdByAccountAddress(address);
        // Fetch rank from the server
        const response = await fetch(`${relayServer}/getRank?userId=${id}`);
        if (!response.ok) {
            throw new Error(`Server error: ${response.status}`);
        }

        const { rank, totalUsers } = await response.json();
        return { rank, totalUsers };
    } catch (error) {
        console.error("Error fetching user rank:", error);
        return { rank: -1 }; // Return -1 in case of an error
    }
};

/**
 * Gets the transaction hash of the user creation.
 * @returns {string|null} - Returns the stored transaction hash.
 */
const getCreateTxHash = () => {
    return getStoredUser()?.txHash;
};

/**
 * Gets the wallet mnemonic or private key.
 * @returns {string|null} - Returns mnemonic phrase or private key.
 */
const getProfileSecret = () => {
    if (wallet)
        return wallet.mnemonic ? wallet.mnemonic.phrase : wallet.privateKey;
    else return null;
};
/**
 * Edits the user profile data.
 * @param {string} username - The username to edit.
 * @param {string} nickname - The user's nickname.
 * @param {string} dob - Date of birth.
 * @param {string} country - User's country.
 * @param {number} gender - User's gender.
 * @returns {Promise<object>} - Result of the profile update.
 */
const editProfile = async (newUsername, nickname, dob, country, gender) => {
    try {
        if (newUsername === await username()) newUsername = "";
        const editProfileData = contracts.userAccount(accountAddress).interface.encodeFunctionData('editProfile', [newUsername, nickname, dob, country, gender]);
        return await propose(editProfileData);
    } catch (e) {
        console.error("ERROR:", e);
        return { status: false, error: e };
    }
};

/**
 * Sets the user profile data via relay server.
 * @param {string} nickname - The user's nickname.
 * @param {string} dob - Date of birth.
 * @param {string} country - User's country.
 * @returns {Promise<object>} - Result of the profile update.
 */
const setUserProfile = async (nickname, dob, country) => {
    if (!nickname || !dob || !country) return;
    try {
        const response = await fetch(`${relayServer}/setProfile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: accountAddress,
                nickname,
                dob,
                country,
            })
        });
        console.log('Response:', response);
        if (response.ok) {
            const responseData = await response.json();
            return { status: true, txHash: responseData.txHash };
        } else {
            const error = await response.json();
            return { status: false, error: error.error };
        }
    } catch (e) {
        return { status: false, error: e };
    }
};


/**
 * Checks if the profile has been set for the account.
 * @returns {Promise<boolean>} - True if profile is set, otherwise false.
 */
const hasSetProfile = async () => {
    try {
        return await contracts.userAccount(accountAddress).profileInitialized();
    } catch (error) {
        console.error("ERROR:", error);
    }
};

/**
 * Fetches a complete user profile including username, user ID, level, badge, XP, rank, followers, following count, and follow status.
 * Uses `Promise.all` to execute all asynchronous requests concurrently for better performance.
 *
 * @param {string} address - The account address of the user (default is the globally defined `accountAddress`).
 * @returns {Promise<object>} - A Promise that resolves to a user profile object with the following structure:
 * {
 *   username: string,        // The username of the user
 *   userId: string,          // The user ID
 *   accountAddress: string,  // The account address
 *   level: number,           // The user's level
 *   badge: string,           // The badge earned by the user
 *   xp: number,              // The total XP of the user
 *   rank: number,            // The user's rank
 *   followers: number,       // The number of followers
 *   following: number,       // The number of users the user is following
 *   isFollowing: boolean     // Whether the user is being followed by the current user
 * }
 */
const getUserProfile = async (address = accountAddress) => {
    try {
        const [
            usernamePromise,
            userIdPromise,
            levelPromise,
            badgePromise,
            xpPromise,
            rankPromise,
            followersPromise,
            followingPromise,
            isFollowingPromise,
            referrerXpPromise,
            refereesCountPromise,
            referralsCountPromise,
        ] = await Promise.all([
            username(address),
            userId(address),
            getLevel(address),
            getBadge(address),
            getTotalXP(address),
            getRank(address),
            getFollowersCount(address),
            getFollowingCount(address),
            isFollowing(address),
            getReferrerEarnedXP(address),
            getRefereesCount(address),
            getReferralsCount(address),
        ]);

        return {
            username: usernamePromise,
            userId: userIdPromise,
            accountAddress: address,
            level: levelPromise,
            badge: badgePromise,
            xp: xpPromise,
            rank: rankPromise,
            followers: followersPromise,
            following: followingPromise,
            isFollowing: isFollowingPromise,
            refXp: referrerXpPromise,
            refereesCount: refereesCountPromise,
            referralsCount: referralsCountPromise
        };
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw error;
    }
};


export {
    username as getUsername,
    userId as getUserId,
    nickname as getNickname,
    dateOfBirth as getDateOfBirth,
    country as getCountry,
    gender as getGender,
    getCreateTxHash,
    getProfileSecret,
    editProfile,
    setUserProfile,
    hasSetProfile,
    getRank,
    getUserProfile
}

window.profile = getUserProfile;