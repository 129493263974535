import React, { useState } from 'react';
export const correctPassword = 'qv~Lb-=O1W1nxXLH5%AC.-JL';

function PasswordPrompt({ onSubmit }) {
  const [password, setPassword] = useState('');
  

  const handleChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === correctPassword) {
      // Save flag to localStorage to indicate that the user is authenticated
      localStorage.setItem('password', correctPassword);
      onSubmit(true);
    } else {
      alert('Incorrect password!');
    }
  };

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw'
    }}>
      <div className='inner-container'>
        <h2>Please enter the password</h2>
        <form onSubmit={handleSubmit}>
          <input 
            type="password" 
            value={password} 
            onChange={handleChange} 
            placeholder="Enter password" 
            required 
          />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
}

export default PasswordPrompt;
