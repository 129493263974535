import { ethers, ZeroAddress } from 'ethers';
import * as account from "../account";
import { contracts } from "./../contracts";
import { getOwnersWallet } from './contractUtils';

const generateTransactionHash = async (data, to = account.accountAddress, value = 0, operation = 0, safeTxGas = 0, baseGas = 0, gasPrice = 0) => {
    const contract = contracts.userAccount(account.accountAddress);
    const nonce = await contract.nonce();
    const txHash = await contract.getTransactionHash(
        to,
        value,
        data,
        operation,
        safeTxGas,
        baseGas,
        gasPrice,
        ZeroAddress,
        ZeroAddress,
        nonce
    );
    return txHash;
};

const collectSignatures = async (txHash) => {
    let signatures = "0x";
    let collectSignatures = 0;
    let i = 0;

    let owners = await account.getOwners();
    let threshold = await account.getThreshold();

    const sortedOwners = owners.map(owner => owner.toLocaleLowerCase()).sort();

    while (collectSignatures < threshold) {
        i++;
        let ownersWallet = getOwnersWallet(sortedOwners[i - 1], sortedOwners);
        if (!ownersWallet) continue;
        const signature = await ownersWallet.signMessage(ethers.toBeArray(txHash));
        if (!signature) continue;
        let sigBytes = ethers.getBytes(signature);
        sigBytes[sigBytes.length - 1] += 4;
        signatures += ethers.hexlify(sigBytes).slice(2);
        console.log(`owner ${sortedOwners[i - 1]} signature: ${ethers.hexlify(sigBytes)}`);
        collectSignatures++;
    }
    console.log(`collected ${collectSignatures} signatures with: ${signatures}`);
    return signatures;
};

export const propose = async (data, to = account.accountAddress, value = 0, operation = 0, safeTxGas = 500000n, baseGas = 0n, gasPrice = 0n, gasToken = ZeroAddress, refundReceiver = ZeroAddress) => {
    try {
        // Step 1: Generate the transaction hash
        const txHash = await generateTransactionHash(data, to, value, operation, safeTxGas, baseGas, gasPrice, gasToken, refundReceiver);
        console.log("generated txHash: " + txHash);

        // Step 2: Collect signatures
        const signatures = await collectSignatures(txHash);

        // Step 3: Execute the transaction using the correct signer
        const txResponse = await contracts.userAccount(account.accountAddress).execTransaction(
            to,
            value,
            data,
            operation,
            safeTxGas,
            baseGas,
            gasPrice,
            gasToken,
            refundReceiver,
            signatures,
        );

        // Wait for the transaction to be mined
        await txResponse.wait();
        console.log('Transaction mined:', txResponse.hash);

        return { status: true, txHash: txResponse.hash };
    } catch (error) {
        console.error('Transaction failed:', error.message);
        return { status: false, error: error.message };
    }
};