import { accountAddress } from "./account";
import { contracts } from "./contracts";
import { relayServer } from "./utils/strings";
// import {admin } from "./admin"
/**
 * Follows a specified user.
 * @param {string} address - The address of the user to follow.
 * @returns {Promise<{status: boolean, txHash?: string, error?: string}>} - Status and transaction hash if successful, or error message.
 */
const follow = async (address) => {
    //FOR TESTING
    //return await admin.follow(address);
    if (!accountAddress || !address) return null;
    try {
        const response = await fetch(`${relayServer}/follow`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                follower: accountAddress,
                followee: address
            })
        });
        if (response.ok) {
            const responseData = await response.json();
            return { status: true, txHash: responseData.txHash };
        } else {
            const error = await response.json();
            return { status: false, error: error["error"] };
        }
    } catch (e) {
        console.error("Error following user:", e);
        return { status: false, error: e.message };
    }
};

/**
 * Unfollows a specified user.
 * @param {string} address - The address of the user to unfollow.
 * @returns {Promise<{status: boolean, txHash?: string, error?: string}>} - Status and transaction hash if successful, or error message.
 */
const unfollow = async (address) => {
    //FOR TESTING
    //return await admin.follow(address);
    if (!accountAddress || !address) return null;
    try {
        const response = await fetch(`${relayServer}/unfollow`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                follower: accountAddress,
                followee: address
            })
        });
        if (response.ok) {
            const responseData = await response.json();
            return { status: true, txHash: responseData.txHash };
        } else {
            const error = await response.json();
            return { status: false, error: error["error"] };
        }
    } catch (e) {
        console.error("Error unfollowing user:", e);
        return { status: false, error: e.message };
    }
};

/**
 * Gets the follower count for a specified address.
 * @param {string} [address=accountAddress] - The address of the user to get follower count for.
 * @returns {Promise<number|null>} - The number of followers, or null on error.
 */
const getFollowersCount = async (address = accountAddress) => {
    try {
        return parseInt(await contracts.followerManager.getFollowersCount(address));
    } catch (e) {
        console.error("Error fetching followers count:", e);
        return null;
    }
};

/**
 * Gets the following count for a specified address.
 * @param {string} [address=accountAddress] - The address of the user to get following count for.
 * @returns {Promise<number|null>} - The number of users followed, or null on error.
 */
const getFollowingCount = async (address = accountAddress) => {
    try {
        return parseInt(await contracts.followerManager.getFollowingCount(address));
    } catch (e) {
        console.error("Error fetching following count:", e);
        return null;
    }
};

/**
 * Gets the list of followers for a specified address.
 * @param {string} [address=accountAddress] - The address of the user to get followers for.
 * @returns {Promise<Array|null>} - An array of follower addresses, or null on error.
 */
const getFollowers = async (address = accountAddress) => {
    try {
        return await contracts.followerManager.getFollowers(address);
    } catch (e) {
        console.error("Error fetching followers:", e);
        return null;
    }
};

/**
 * Gets the list of users that a specified address is following.
 * @param {string} [address=accountAddress] - The address of the user to get following list for.
 * @returns {Promise<Array|null>} - An array of followed addresses, or null on error.
 */
const getFollowing = async (address = accountAddress) => {
    try {
        return await contracts.followerManager.getFollowing(address);
    } catch (e) {
        console.error("Error fetching following list:", e);
        return null;
    }
};

/**
 * Checks if the current user is following a specified address.
 * @param {string} address - The address to check.
 * @returns {Promise<boolean|null>} - True if following, false if not, or null on error.
 */
const isFollowing = async (address) => {
    try {
        if(accountAddress){
            return await contracts.followerManager.isFollowing(accountAddress, address);
        }else {
            return false;
        }
    } catch (e) {
        console.error("Error checking following status:", e);
        return null;
    }
};

export {
    follow,
    unfollow,
    getFollowers,
    getFollowing,
    getFollowersCount,
    getFollowingCount,
    isFollowing
};
