import { contracts, provider } from './contracts';
import { accountAddress } from './account';
import { propose } from './utils/transaction';
import { blacxesAddress, blacxesLockerAddress, relayServer } from './utils/strings';
import { getBigInt, ZeroAddress } from 'ethers';

/**
 * Fetches the balance of Blacxes tokens for a given address.
 * @param {string} address - The address to check the balance for.
 * @returns {Promise<number>} - Blacxes token balance.
 */
const balanceOf = async (address = accountAddress) => {
    try {
        return await contracts.blacxes.balanceOf(address, 0);
    } catch (e) {
        console.error("ERROR fetching Blacxes balance:", e);
        return null;
    }
};

/**
 * Fetches the locked balance of Blacxes tokens for a given address.
 * @param {string} address - The address to check the balance for.
 * @returns {Promise<number>} - Blacxes locked token balance.
 */
const lockedBalance = async (address = accountAddress) => {
    try {
        return await contracts.blacxesLocker.getTotalLockedForUser(address);
    } catch (e) {
        console.error("ERROR fetching Blacxes balance:", e);
        return null;
    }
};

/**
 * Fetches the total Blacxes tokens for a given address.
 * @param {string} address - The address to get the total Blacxes for.
 * @returns {Promise<number>} - Total Blacxes balance.
 */
const totalBlacxes = async (address = accountAddress) => {
    try {
        return parseInt(await contracts.userAccount(address).totalBlacxes());
    } catch (e) {
        console.error("ERROR fetching total Blacxes:", e);
        return null;
    }
};

/**
 * Fetches the available Blacxes tokens for locking.
 * @param {string} address - The address of the user.
 * @returns {Promise<number>} - Available Blacxes tokens for locking.
 */
const availableForLock = async (address = accountAddress) => {
    try {
        const availableAmount = await contracts.blacxesLocker.availableForLock(address);
        return availableAmount;
    } catch (e) {
        console.error("ERROR fetching available tokens for lock:", e);
        return null;
    }
};

/**
 * Fetches the transferable Blacxes tokens for a specific user.
 * @param {string} address - The address of the user.
 * @returns {Promise<number>} - The amount of transferable Blacxes tokens.
 */
const getTransferableBlacxes = async (address = accountAddress) => {
    try {
        const transferableAmount = await contracts.blacxes.getTransferableBlacxes(address);
        return transferableAmount;
    } catch (e) {
        console.error("ERROR fetching transferable Blacxes:", e);
        return null;
    }
};

/**
 * Locks Blacxes tokens for a specific user.
 * @param {number} amount
 * @param {string} lockTime
 * @returns {Promise<object>} - Result of the lockTokens transaction.
 */
const lockTokens = async (amount, lockTime) => {
    try {
        if(!(await isApproved()))
            await approveLocker();
        console.log("Locker approved:", await isApproved());
        const lockTokensData = contracts.blacxesLocker.interface.encodeFunctionData('lockTokens', [amount, lockTime]);
        return await propose(lockTokensData, blacxesLockerAddress);
    } catch (e) {
        console.error("ERROR locking Blacxes tokens:", e);
        return null;
    }
};

/**
 * approve Locker address to lock tokens
 */
const approveLocker = async () => {
    try {
        if(await isApproved())
            return;
        const approveData = contracts.blacxes.interface.encodeFunctionData('setApprovalForAll', [blacxesLockerAddress, true]);
        return await propose(approveData, blacxesAddress);
    } catch (e) {
        console.error("ERROR approving locker:", e);
    }
};

/**
 * approve Locker address to lock tokens
 */
const isApproved = async () => {
    try {
        return await contracts.blacxes.isApprovedForAll(accountAddress, blacxesLockerAddress);
    } catch (e) {
        console.error("ERROR approving locker:", e);
        return null;
    }
};

/**
 * Unlocks Blacxes tokens for a user.
 * @param {number} lockId - The ID of the lock to unlock.
 * @returns {Promise<object>} - Transaction result.
 */
const unlockTokens = async (lockId, userAddress = accountAddress) => {
    try {
        // Get the lock details first to validate
        const userLocks = await contracts.blacxesLocker.getUserLocks(userAddress);
        const lockToUnlock = userLocks.find(lock => lock.lockId.toString() === lockId.toString());
        
        if (!lockToUnlock) {
            throw new Error("Lock not found");
        }

        if (lockToUnlock.isReleased) {
            throw new Error("Lock already released");
        }

        const currentTime = Math.floor(Date.now() / 1000);
        const unlockTime = parseInt(lockToUnlock.unlockTime);
        
        if (currentTime < unlockTime) {
            throw new Error("Lock period not ended yet");
        }

        // Encode the unlock function call
        const unlockData = contracts.blacxesLocker.interface.encodeFunctionData('unlockTokens', [
            lockId
        ]);

        // Send the transaction with specific parameters
        const result = await propose(
            unlockData,
            blacxesLockerAddress,
            0n, // value
            0, // operation
            300000n, // gas limit
            0n, // base gas
            0n, // gas price
            ZeroAddress, // gas token
            ZeroAddress // refund receiver
        );

        if (!result || !result.txHash) {
            throw new Error("Transaction failed to be proposed");
        }

        // Wait for transaction confirmation
        const receipt = await provider.waitForTransaction(result.txHash);
        
        if (!receipt.status) {
            throw new Error("Transaction failed");
        }

        // Verify the unlock was successful
        const updatedLocks = await contracts.blacxesLocker.getUserLocks(userAddress);
        const updatedLock = updatedLocks.find(lock => lock.lockId.toString() === lockId.toString());
        
        if (!updatedLock.isReleased) {
            throw new Error("Unlock verification failed");
        }

        return {
            status: true,
            txHash: result.txHash,
            receipt
        };

    } catch (e) {
        console.error("Unlock tokens error:", e);
        return {
            status: false,
            error: e.message,
            details: e
        };
    }
};


/**
 * Fetches the active locks for the current user.
 * @param {string} address - The address of the user.
 * @returns {Promise<number>} - The number of active locks for the user.
 */
const activeUserLocks = async (address = accountAddress) => {
    try {
        const result = await contracts.blacxesLocker.activeUserLocks(address);
        return result;
    } catch (e) {
        console.error("ERROR fetching active user locks:", e);
        return null;
    }
};

/**
 * Awards Blacxes tokens to a specified user.
 * @param {string} address - Address of the user to receive tokens.
 * @param {number} packageId - ID of the Blacxes package.
 * @param {number} amount - Amount of tokens to give.
 * @returns {Promise<void>}
 */
const giveBlacxesToUser = async (address = accountAddress, packageId = 0, amount = 10) => {
    try {
        const response = await fetch(`${relayServer}/giveBlacxesToUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: address,
                packageId: packageId,
                amount: amount
            })
        });
        if (response.ok) {
            const responseData = await response.json();
            console.log(`Transaction mined with txHash: ${responseData.txHash}`);
        } else {
            const error = await response.json();
            console.error("Error executing transaction:", error["error"]);
        }
    } catch (e) {
        console.error('Error giving user Blacxes:', e);
    }
};

/**
 * Fetches all locks for a given user.
 * @param {string} address - The address of the user.
 * @returns {Promise<Array>} - A list of locks for the user.
 */
const getUserLocks = async (address = accountAddress) => {
    try {
        const locks = await contracts.blacxesLocker.getUserLocks(address);
        // Format locks for readability
        return locks.map(lock => ({
            lockId: lock.lockId.toString(),
            amount: lock.amount,
            lockTime: new Date(parseInt(lock.lockTime) * 1000).toISOString(), // Convert to readable date
            unlockTime: new Date(parseInt(lock.unlockTime) * 1000).toISOString(), // Convert to readable date
            isReleased: lock.isReleased,
        }));
    } catch (e) {
        console.error("ERROR fetching user locks:", e);
        return null;
    }
};

/**
 * Retrieves the list of available Blacxes packages.
 * @returns {Promise<Array>} - Array of available Blacxes packages.
 */
const getAvailablePackages = async () => {
    try {
        return await contracts.blacxes.getAvailablePackages();
    } catch (e) {
        console.error("ERROR fetching available packages:", e);
        return null;
    }
};

/**
 * Fetches the base price of one Blacxes token.
 * @returns {Promise<number>} - Base price of a Blacxes token.
 */
const basePrice = async () => {
    try {
        return await contracts.blacxes.basePrice();
    } catch (e) {
        console.error("ERROR fetching Blacxes price:", e);
        return null;
    }
}

/**
 * Buys Blacxes tokens.
 * @param {number} amount - Amount of tokens to buy.
 * @returns {Promise<object>} - Result of the buy transaction.
 */
const buyBlacxes = async (amount) => {
    try {
        const price = (await basePrice()) * (getBigInt(amount));
        const txResponse = await contracts.blacxes.buy(amount, {value: price});
        await txResponse.wait();
        console.log('Transaction mined:', txResponse.hash);
        return txResponse.hash;
    } catch (e) {
        console.error(e.reason);
        return null;
    }
};

/**
 * Buys a Blacxes package.
 * @param {string} address - The address of the buyer.
 * @param {number} packageId - The package ID to purchase.
 * @returns {Promise<object>} - Transaction result.
 */
const buyPackage = async (address = accountAddress, packageId = 0) => {
    try {
        const buyData = contracts.blacxes.interface.encodeFunctionData('buyPackage', [address, packageId]);
        const result = await propose(buyData, contracts.blacxesAddress);
        return result;
    } catch (e) {
        console.error("ERROR buying Blacxes package:", e);
        return null;
    }
};

/**
 * Transfers a Blacxes package.
 * @param {string} to - The address to transfer the package to.
 * @param {number} packageId - The ID of the package to transfer.
 * @returns {Promise<object>} - Transaction result.
 */
const transferPackage = async (to, packageId) => {
    try {
        const transferData = contracts.blacxes.interface.encodeFunctionData('transferPackage', [to, packageId]);
        const result = await propose(transferData, contracts.blacxesAddress); 
        return result;
    } catch (e) {
        console.error("ERROR transferring Blacxes package:", e);
        return null;
    }
};

/**
 * Safely transfers Blacxes tokens from one address to another.
 * @param {string} from - The address to transfer from.
 * @param {string} to - The address to transfer to.
 * @param {number} tokenId - The ID of the token to transfer.
 * @param {number} amount - The amount of the token to transfer.
 * @param {string} data - Additional data for the transfer (can be empty).
 * @returns {Promise<object>} - Transaction result.
 */
const safeTransferFrom = async (from, to, amount) => {
    try {
        const txResponse = await contracts.blacxes.safeTransferFrom(from, to, 0, amount, '0x');
        await txResponse.wait();
        console.log('Transaction mined:', txResponse.hash);
        return txResponse.hash;
    } catch (e) {
        console.error("ERROR transferring Blacxes token:", e);
        return null;
    }
};

/**
 * Transfers the ownership of the Blacxes contract.
 * @param {string} newOwner - The address of the new owner.
 * @returns {Promise<object>} - Transaction result.
 */
const transferOwnership = async (newOwner) => {
    try {
        const transferData = contracts.blacxes.interface.encodeFunctionData('transferOwnership', [newOwner]);
        const result = await propose(transferData, contracts.blacxesAddress); 
        return result;
    } catch (e) {
        console.error("ERROR transferring contract ownership:", e);
        return null;
    }
};

/**
 * Divides a Blacxes package.
 * @param {number} packageId - The ID of the package to divide.
 * @returns {Promise<object>} - Transaction result.
 */
const dividePackage = async (packageId) => {
    try {
        const divideData = contracts.blacxes.interface.encodeFunctionData('dividePackage', [packageId]);
        const result = await propose(divideData, contracts.blacxesAddress); // Multi-sig transaction proposal
        return result;
    } catch (e) {
        console.error("ERROR dividing Blacxes package:", e);
        return null;
    }
};

/**
 * Fetches the total number of available Blacxes packages.
 * @returns {Promise<number>} - Total number of packages.
 */
const totalPackages = async () => {
    try {
        const total = await contracts.blacxes.totalPackages();
        return total;
    } catch (e) {
        console.error("ERROR fetching total packages:", e);
        return null;
    }
};

export {
    balanceOf as getBlacxesBalance,
    lockedBalance as getLockedBlacxesBalance,
    getTransferableBlacxes,
    lockTokens as lockBlacxes,
    unlockTokens as unlockBlacxes,
    getAvailablePackages,
    totalBlacxes as getTotalBlacxes,
    basePrice as getBlacxesPrice,
    buyBlacxes,
    activeUserLocks as getActiveUserLocks,
    availableForLock as getAvailableForLock,
    getUserLocks,
    buyPackage,
    dividePackage,
    totalPackages,
    transferPackage,
    transferOwnership,
    giveBlacxesToUser,
    safeTransferFrom as transferBlacxes,
};

window.blacxes = {
    balanceOf,
    lockedBalance,
    lockTokens,
    getAvailablePackages,
    totalBlacxes,
    basePrice,
    buyBlacxes,
    activeUserLocks,
    availableForLock,
    getUserLocks,
    buyPackage,
    dividePackage,
    totalPackages,
    transferPackage,
    transferOwnership,
    safeTransferFrom,
    getTransferableBlacxes,
    unlockTokens,
};