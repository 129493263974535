import React from 'react';

const Modal = ({ title, isOpen, onClose, children, width = '600px' }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div
        className="modal-content"
        onClick={(e) => e.stopPropagation()}
        style={{ width }} // Apply the custom width here
      >
        {title && <h1>{title}</h1>}
        {children}
      </div>
    </div>
  );
};

export default Modal;
