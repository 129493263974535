import { accountAddress } from "./account";
import { contracts } from "./contracts";
import { relayServer } from "./utils/strings";
import { propose } from "./utils/transaction";

/**
 * Fetches the bio associated with the account.
 * @param {string} address - The address to fetch bio for.
 * @returns {Promise<string|null>} - Bio or null if not found.
 */
const getBio = async (address = accountAddress) => {
    return await contracts.bio.getBio(address);
};

/**
 * Checks if the bio has been set for the account.
 * @returns {Promise<boolean>} - True if bio is set, otherwise false.
 */
const hasSetBio = async () => {
    return await contracts.bio.isBioInitialized(accountAddress);
};

/**
 * Gets the maximum bio length allowed.
 * @returns {Promise<number>} - Maximum bio length or 250 if error occurs.
 */
const getMaxBioLength = async () => {
    try {
        return await contracts.bio.MAX_BIO_LENGTH();
    } catch (error) {
        console.error("ERROR:", error);
        return 250;
    }
};

/**
 * Sets the user bio via relay server.
 * @param {string} bio - The bio text to set.
 * @returns {Promise<object>} - Result of the bio update.
 */
const setUserBio = async (bio) => {
    if (!accountAddress || !bio) return null;
    try {
        const response = await fetch(`${relayServer}/setBio`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user: accountAddress, bio })
        });
        if (response.ok) {
            const responseData = await response.json();
            return { status: true, txHash: responseData.txHash };
        } else {
            const error = await response.json();
            return { status: false, error: error.error };
        }
    } catch (e) {
        return { status: false, error: e };
    }
};

/**
 * Edits the user bio.
 * @param {string} bio - The bio text to update.
 * @returns {Promise<object>} - Result of the bio update.
 */
const editBio = async (bio) => {
    try {
        const editBioData = contracts.bio.interface.encodeFunctionData('editBio', [bio]);
        return await propose(editBioData);
    } catch (e) {
        return { status: false, error: e };
    }
};



export { getBio, hasSetBio, getMaxBioLength, setUserBio, editBio };