import { contracts } from "./contracts";
import { accountAddress } from "./account";
import { legendaryOli, usernameByAccountAddress } from "./utils/contractUtils";

/**
 * Retrieves the referrer address of the specified user account.
 * @param {string} [address=accountAddress] - The address to check for a referrer.
 * @returns {Promise<string|null>} - The referrer's address or null on error.
 */
const referrer = async (address = accountAddress) => {
    try {
        return await contracts.userAccount(address).parentReferral();
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Retrieves the username of the referrer for the specified user account.
 * @param {string} [address=accountAddress] - The address to fetch the referrer username for.
 * @returns {Promise<string|null>} - The referrer's username or null on error.
 */
const referrerUsername = async (address = accountAddress) => {
    try {
        let referrerAddress = await referrer(address);
        if (referrerAddress && referrerAddress !== await legendaryOli()) {
            return await usernameByAccountAddress(referrerAddress);
        }
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Retrieves all referrals made by the specified user account.
 * @param {string} [address=accountAddress] - The address to fetch referrals for.
 * @returns {Promise<Array<string>|null>} - An array of referral addresses or null on error.
 */
const referrals = async (address = accountAddress) => {
    try {
        return await contracts.referral.getAllReferrers(address);
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Retrieves the count of all referrals made by the specified user account.
 * @param {string} [address=accountAddress] - The address to check for the referral count.
 * @returns {Promise<number|null>} - The count of referrals or null on error.
 */
const referralsCount = async (address = accountAddress) => {
    try {
        const refs = await referrals(address);
        return refs ? refs.length : null;
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Retrieves all referees (users referred by others) for the specified account.
 * @param {string} [address=accountAddress] - The address to fetch referees for.
 * @returns {Promise<Array<string>|null>} - An array of referee addresses or null on error.
 */
const referees = async (address = accountAddress) => {
    try {
        return await contracts.referral.getReferees(address);
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Retrieves the count of all referees for the specified user account.
 * @param {string} [address=accountAddress] - The address to check for the referees count.
 * @returns {Promise<number|null>} - The count of referees or null on error.
 */
const refereesCount = async (address = accountAddress) => {
    try {
        const refs = await referees(address);
        return refs ? refs.length : null;
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

export {
    referrer as getReferrer,
    referrerUsername as getReferrerUsername,
    referees as getReferees,
    refereesCount as getRefereesCount,
    referrals as getReferrals,
    referralsCount as getReferralsCount
};

window.referral = {
    refereesCount,
    referralsCount
}