import { Contract, JsonRpcProvider } from 'ethers';
import * as strings from './utils/strings';

let provider = null;
let wallet = null;
let contracts = {};
let contractsReadyResolve = null;
let contractsReady = new Promise((r)=>{contractsReadyResolve = r});

const initializeContracts = async (userWallet) => {
    provider = new JsonRpcProvider(strings.rpcUrl);
    wallet = userWallet.connect(provider);
    
    // Initialize the ContractsManager contract
    const contractsManager = new Contract(
        strings.contractsManagerAddress,
        strings.contractsManagerABI,
        wallet
    );

    try {
        // Dynamically retrieve all contract addresses
        const contractsInfo = await contractsManager.getAllContractsInfo();
        
        // Create a new object to store contract addresses by name
        const newAddresses = {};

        // Update newAddresses object
        contractsInfo.forEach(({ name, addr }) => {
            newAddresses[name] = addr;
        });

        // Update addresses in strings.js
        strings.updateAddresses(newAddresses);

        // Initialize contracts dynamically based on retrieved addresses
        contracts = {
            userAccount: (address) => new Contract(address, strings.userAccountABI, wallet),
            userCreator: new Contract(strings.userCreatorAddress, strings.userCreatorABI, wallet),
            levels: new Contract(strings.levelsAddress, strings.accesLevelABI, wallet),
            xp: new Contract(strings.xpAddress, strings.accesXPABI, wallet),
            referral: new Contract(strings.referralAddress, strings.referralABI, wallet),
            followerManager: new Contract(strings.followerManagerAddress, strings.followerManagerABI, wallet),
            blacxes: new Contract(strings.blacxesAddress, strings.balcxesABI, wallet),
            blacxesLocker: new Contract(strings.blacxesLockerAddress, strings.blacxesLockerABI, wallet),
            accesLocker: new Contract(strings.accesLockerAddress, strings.accesLockerABI, wallet),
            bio: new Contract(strings.bioAddress, strings.userBioABI, wallet),
            oli: new Contract(strings.oliAddress, strings.oliNFTABI, wallet),
            dataManager: new Contract(strings.dataManagerAddress, strings.accesDataManagerABI, wallet),
            forwarder: new Contract(strings.forwarderAddress, strings.forwarderABI, wallet)
        };
        contractsReadyResolve(true);
        console.log("Contracts initialized dynamically", contracts);
    } catch (error) {
        console.error("Error initializing contracts dynamically:", error);
        contractsReadyResolve(false);
    }
};

export { initializeContracts, contracts, contractsReady, provider };