import {compressSync, decompressSync} from 'fflate'

/**
 * Copy a string to the clipboard
 * @param {*} textToCopy 
 */
const copyToClipboard = async (textToCopy) => {
    try {
        if (navigator.clipboard)
            await navigator.clipboard.writeText(textToCopy);
        else {
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            document.body.appendChild(textArea);
            textArea.focus({ preventScroll: true });
            textArea.select();
            try {
                document.execCommand('copy');
            } catch (err) {
                console.error('Unable to copy to clipboard', err);
            }
            document.body.removeChild(textArea);
        }
    } catch (err) {
        console.error('Failed to copy!', err);
    }
};

/***
 * Get a formatted string with the first and last characters visible
 * @param {string} str - The string to format
 * @param {number} first - The number of characters to show at the start - default 4
 * @param {number} last - The number of characters to show at the end - default 4
 */
const getShortString = (str, first = 4, last = 4) => {
    if (!str) return '';
    if (str.length <= first + last) return str;
    return `${str.substring(0, first)}...${str.substring(str.length - last)}`;
};

/**
 * Compresses JSON data.
 * @param {object} data - Data to compress.
 * @returns {string} Compressed data in base64 format.
 */
const compressJson = (data) => {
    const jsonString = JSON.stringify(data);
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(jsonString);
    const compressed = compressSync(encodedData, { level: 11 });
    const base64String = arrayBufferToBase64(compressed);
    return base64String;
}

/**
 * Decompresses base64 data into JSON.
 * @param {string} base64 - Compressed base64 data.
 * @returns {object} Decompressed JSON data.
 */
const decompressJson = (base64) => {
    const compressedData = base64ToUint8Array(base64);
    const decompressed = decompressSync(compressedData);
    const decompressedString = new TextDecoder().decode(decompressed);
    const jsonObject = JSON.parse(decompressedString);
    return jsonObject;
}

/**
 * Stores user account data in localStorage.
 * @param {object} user - User data to store.
 */
const storeUser = (user) => {
    localStorage.setItem('userAccount', compressJson(user));
};

/**
 * Retrieves stored user data from localStorage.
 * @returns {object|null} Decompressed user data or null if not found.
 */
const getStoredUser = () => {
    const data = localStorage.getItem('userAccount');
    return data ? decompressJson(data) : null;
};

/**
* Converts a Uint8Array to a Base64 string.
* @param {Uint8Array} buffer
* @returns {string}
*/
const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const len = buffer.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(buffer[i]);
    }
    return window.btoa(binary);
}

/**
 * Converts a Base64 string to a Uint8Array.
 * @param {string} base64
 * @returns {Uint8Array}
 */
const base64ToUint8Array = (base64) => {
    const binary = window.atob(base64);
    const len = binary.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    return bytes;
}

/**
 * Formats a number with max decimal digits.
 * @param {number} number - The number to format.
 * @param {number} digits - The number of decimal digits to show.
 * @returns {string} - The formatted number.
 */
const formatNumber = function (number, digits = 2) {
    try{
        return parseFloat(number).toFixed(digits);
    }catch(e){
        console.error(e);
    }
};

export {
    copyToClipboard,
    getStoredUser,
    storeUser,
    getShortString,
    formatNumber
}