import { Wallet, ZeroAddress } from 'ethers';
import { getStoredUser, storeUser } from './utils/utils';
import { contracts, initializeContracts, provider } from './contracts';
import { forwarderAddress, relayServer, userCreatorAddress } from './utils/strings';
import { propose } from './utils/transaction';
import { accountAddressByName, generateNewAddress, getOwnersWallet, isDerivedWallet, legendaryOli } from './utils/contractUtils';
// import { admin } from './admin'
// Default user account address
var accountAddress = getStoredUser()?.address;

/**
 * Initializes the wallet from stored mnemonic or creates a new one.
 * @returns {Wallet} - Returns an initialized wallet instance.
 */
const initializeWalletAndContracts = () => {
    const storedUser = getStoredUser();
    let tempWallet = storedUser
        ? Wallet.fromPhrase(storedUser.mnemonic)
        : Wallet.createRandom();
    initializeContracts(tempWallet);
    return tempWallet;
};

var wallet = initializeWalletAndContracts();

/**
 * Fetches the main wallet associated with the account.
 * @param {string} address - The address to fetch the main wallet for.
 * @returns {Promise<string|null>} - Main wallet address or null if not found.
 */
const mainWallet = async (address = accountAddress) => {
    try {
        const mainWallet = await contracts.userAccount(address).mainWallet();
        console.log("Main wallet:", mainWallet);
        if(mainWallet && mainWallet !== ZeroAddress && address === accountAddress){
            wallet = getOwnersWallet(mainWallet, await owners());
            initializeContracts(wallet);
        }
        return mainWallet && mainWallet !== ZeroAddress ? mainWallet : null;
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Fetches all wallets associated with the account.
 * @param {string} address - The address to fetch wallets for.
 * @returns {Promise<Array>|null} - List of wallet addresses or null if not found.
 */
const wallets = async (address = accountAddress) => {
    try {
        return await contracts.userAccount(address).getAllWallets();
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Fetches the threshold of the account.
 * @param {string} address - The address to fetch the threshold for.
 * @returns {Promise<number|null>} - Threshold value or null if not found.
 */
const threshold = async (address = accountAddress) => {
    try {
        return parseInt(await contracts.userAccount(address).getThreshold());
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Fetches the owners associated with the account.
 * @param {string} address - The address to fetch owners for.
 * @returns {Promise<Array>|null} - List of owner addresses or null if not found.
 */
const owners = async (address = accountAddress) => {
    try {
        return await contracts.userAccount(address).getOwners();
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Adds a new wallet address to the user's account.
 * @param {boolean} increaseThreshold - Whether to increase the wallet threshold.
 * @returns {Promise<object>} - Transaction result status and data.
 */
const addWallet = async (increaseThreshold) => {
    try {
        const newThreshold = parseInt(await threshold()) + (increaseThreshold ? 1 : 0);
        const newWallet = await generateNewAddress(wallet.mnemonic.phrase, await wallets());
        const addWalletData = contracts.userAccount(accountAddress).interface.encodeFunctionData('addWallet', [newWallet, newThreshold]);
        const result = await propose(addWalletData);
        return result;
    } catch (e) {
        console.error("ERROR:", e);
        return { status: false, error: e };
    }
};

/**
 * Withdraws an NFT to a specific address.
 * @param {string} nftAddress - The address of the NFT contract.
 * @param {string} to - The recipient address.
 * @param {number} tokenId - The ID of the NFT to withdraw.
 * @returns {Promise<object>} - The transaction result.
 */
const withdrawNFT = async (nftAddress, to, tokenId) => {
    try {
        const withdrawNFTData = contracts.userAccount(accountAddress).interface.encodeFunctionData('withdrawNFT', [nftAddress, to, tokenId]);
        const result = await propose(withdrawNFTData);
        return result;
    } catch (e) {
        console.error("ERROR:", e);
        return { status: false, error: e.message };
    }
};

/**
 * Changes the main wallet of the user account.
 * @param {string} newMainWallet - The address of the new main wallet.
 * @returns {Promise<object>} - The transaction result.
 */
const changeMainWallet = async (newMainWallet) => {
    try {
        const tx = await contracts.userAccount(accountAddress).changeMainWallet(newMainWallet);
        console.log(`Transaction submitted. Hash: ${tx.hash}`);
        const res = await tx.wait();
        setTimeout(async () => {
            wallet = getOwnersWallet(await mainWallet());
            return res.hash;
        },1000);
    } catch (e) {
        console.error("ERROR:", e);
        return { status: false, error: e.message };
    }
};

/**
 * Changes the threshold for the user account.
 * @param {number} newThreshold - The new threshold value.
 * @returns {Promise<object>} - The transaction result.
 */
const changeThreshold = async (newThreshold) => {
    try {
        const changeThresholdData = contracts.userAccount(accountAddress).interface.encodeFunctionData('changeThreshold', [newThreshold]);
        const result = await propose(changeThresholdData);
        return result;
    } catch (e) {
        console.error("ERROR:", e);
        return { status: false, error: e.message };
    }
};

/**
 * Removes an owner from the user account.
 * @param {string} ownerToRemove - The address of the owner to be removed.
 * @returns {Promise<object>} - The transaction result.
 */
const removeOwner = async (ownerToRemove) => {
    try {
        console.log("Starting removeOwner process...");
        console.log("Owner to remove:", ownerToRemove);

        // Get all owners to find the correct prevOwner
        const ownersList = await owners();  // Changed from getOwners to owners
        console.log("Current owners:", ownersList);

        // Find the correct prevOwner that points to ownerToRemove
        let prevOwner;
        for (let i = 0; i < ownersList.length; i++) {
            const nextOwner = i + 1 < ownersList.length ? ownersList[i + 1] : null;
            if (nextOwner === ownerToRemove) {
                prevOwner = ownersList[i];
                break;
            }
        }
        console.log("Found correct prevOwner:", prevOwner);

        // Calculate new threshold
        const currentThreshold = await threshold();
        const ownerCount = ownersList.length;
        let newThreshold = currentThreshold;
        if(currentThreshold === ownerCount){
            newThreshold = currentThreshold - 1;
        }
        console.log("Current threshold:", currentThreshold);
        console.log("New threshold:", newThreshold);

        console.log("Encoding removeOwner function data...");
        const removeOwnerData = contracts.userAccount(accountAddress).interface.encodeFunctionData(
            'removeOwner',
            [prevOwner, ownerToRemove, newThreshold]
        );
        console.log("Encoded data:", removeOwnerData);

        const result = await propose(
            removeOwnerData,    
            accountAddress,     
            0,                 
            0,                 
            500000n,          
            0n,               
            0n,               
            ZeroAddress,      
            ZeroAddress       
        );

        console.log("Transaction result:", result);
        return result;
    } catch (e) {
        console.error("ERROR in removeOwner:", e);
        console.error("Error stack:", e.stack);
        return { status: false, error: e.message };
    }
};
/**
 * Removes a wallet from the user account.
 * @param {string} walletAddress - The address of the wallet to be removed.
 * @param {number} newThreshold - The new threshold after removing the wallet.
 * @returns {Promise<object>} - The transaction result.
 */
const removeWallet = async (walletToRemove) => {
    try {
        console.log("Starting removeOwner process...");
        console.log("Owner to remove:", walletToRemove);

        // Get all owners to find the correct prevOwner
        const ownersList = await owners();  // Changed from getOwners to owners
        console.log("Current owners:", ownersList);

        // Find the correct prevOwner that points to ownerToRemove
        let prevOwner;
        for (let i = 0; i < ownersList.length; i++) {
            const nextOwner = i + 1 < ownersList.length ? ownersList[i + 1] : null;
            if (nextOwner === walletToRemove) {
                prevOwner = ownersList[i];
                break;
            }
        }
        console.log("Found correct prevOwner:", prevOwner);

        // Calculate new threshold
        const currentThreshold = await threshold();
        const ownerCount = ownersList.length;
        let newThreshold = currentThreshold;
        if(currentThreshold === ownerCount){
            newThreshold = currentThreshold - 1;
        }
        console.log("Current threshold:", currentThreshold);
        console.log("New threshold:", newThreshold);

        console.log("Encoding removeOwner function data...");
        const removeWalletData = contracts.userAccount(accountAddress).interface.encodeFunctionData(
            'removeWallet',
            [prevOwner, walletToRemove, newThreshold]
        );
        console.log("Encoded data:", removeWalletData);

        const result = await propose(removeWalletData);

        console.log("Transaction result:", result);
        return result;
    } catch (e) {
        console.error("ERROR in removeOwner:", e);
        console.error("Error stack:", e.stack);
        return { status: false, error: e.message };
    }
};


/**
 * Creates a new user account.
 * @param {string} username - The username for the new account.
 * @param {number} gender - The gender identifier for the account.
 * @param {string} referrer - The referrer's address.
 * @returns {Promise<{status: boolean, address?: string, error?: string}>} - Status and address if successful, or error message.
 */
const createUser = async (username, gender, referrer) => {

    // // FOR TESTING
    // const responseData = await admin.createUser(wallet, username, gender, referrer);

    // if(responseData.success){
    //     storeUser({
    //         address: responseData.address,
    //         txHash: responseData.txHash,
    //         mnemonic: wallet.mnemonic ? wallet.mnemonic.phrase : wallet.privateKey
    //     });
    //     wallet = initializeWalletAndContracts();
    //     accountAddress = responseData.address;
    //     return { status: true, address: responseData.address };
    // }else 
    //     return { status: false, error: responseData.error };

    if (!username) {
        return { status: false, error: 'Please enter a username.' };
    }
    if (!validateUsername(username)) {
        return { status: false, error: 'Username is not valid, use characters from 5 to 16' };
    }
    if (referrer && !/^0x[a-fA-F0-9]{40}$/.test(referrer)) {
        referrer = await accountAddressByName(referrer);
    }
    if (referrer && (!/^0x[a-fA-F0-9]{40}$/.test(referrer) || referrer === ZeroAddress)) {
        referrer = await legendaryOli();
    }
    if (!referrer) {
        referrer = await legendaryOli();
    }
    const encodedFunctionCall = contracts.userCreator.interface.encodeFunctionData('createUserAccountAndActivate', [username, gender, referrer]);

    const request = {
        from: wallet.address,
        to: userCreatorAddress,
        value: 0,
        gas: 5000000,
        nonce:"0",
        data: encodedFunctionCall,
    };

    try {
        const chainId = (await provider.getNetwork()).chainId;
        const domain = {
            name: 'MinimalForwarder',
            version: '0.0.1',
            chainId: parseInt(chainId),
            verifyingContract: forwarderAddress,
        };
        const types = {
            ForwardRequest: [
                { name: 'from', type: 'address' },
                { name: 'to', type: 'address' },
                { name: 'value', type: 'uint256' },
                { name: 'gas', type: 'uint256' },
                { name: 'nonce', type: 'uint256' },
                { name: 'data', type: 'bytes' },
            ],
        };

        const signature = await wallet.signTypedData(domain, types, request);
        console.log('Signature:', signature);
        const requestWithSignature = {
            username,
            ...request,
            signature: signature,
            gasPrice: (await provider.getFeeData()).gasPrice.toString(),
        };

        const response = await fetch(`${relayServer}/createUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestWithSignature),
        });

        if (response.ok) {
            const responseData = await response.json();
            if (responseData.success) {
                console.log(`User account created! Contract Address: ${responseData.address}`);
                storeUser({
                    address: responseData.address,
                    txHash: responseData.txHash,
                    mnemonic: wallet.mnemonic ? wallet.mnemonic.phrase : wallet.privateKey
                });
                wallet = initializeWalletAndContracts();
                accountAddress = responseData.address;
                return { status: true, address: responseData.address };
            } else {
                console.error(responseData);
                return { status: false, error: responseData };
            }
        } else {
            const error = await response.json();
            console.error("Error executing transaction:", error["error"]);
            return { status: false, error: error["error"] };
        }
    } catch (error) {
        console.error('Error signing and sending transaction:', error);
        return { status: false, error: error.message };
    }
};

/**
 * Imports a user account by mnemonic phrase.
 * @param {string} username - Username to import.
 * @param {string} phrase - Mnemonic phrase for the wallet.
 * @returns {Promise<object>} - Import result.
 */
const importUser = async (username, phrase) => {
    try {
        let tempWallet;
        if (!validateUsername(username)) {
            return { status: false, error: 'Username is not valid, use characters from 5 to 16' };
        }
        if (phrase.split(' ').length === 12) {
            tempWallet = Wallet.fromPhrase(phrase).connect(provider);
        } else {
            return { status: false, error: 'Invalid access key, it should be 12 words' };
        }
        const userAccountAddress = await accountAddressByName(username);
        const walletsFromContract = await contracts.userAccount(userAccountAddress).getAllWallets();
        const mainContractWallet = await contracts.userAccount(userAccountAddress).mainWallet();
        if (walletsFromContract.includes(tempWallet.address) || isDerivedWallet(mainContractWallet, phrase)) {
            storeUser({
                address: userAccountAddress,
                mnemonic: tempWallet.mnemonic ? tempWallet.mnemonic.phrase : tempWallet.privateKey
            });
            accountAddress = userAccountAddress;
            wallet = await mainWallet(userAccountAddress);
            return { status: true, address: userAccountAddress };
        }
        return { status: false, error: 'Wallet does not match user account' };
    } catch (e) {
        console.error('Could not import', e);
        return { status: false, error: e.message };
    }
};

/**
 * Validates the username based on length and characters.
 * @param {string} username - The username to validate.
 * @returns {boolean} - True if valid, otherwise false.
 */
const validateUsername = (username) => {
    return !(username.length < 5 || /^[0-9]+$/.test(username) || username.length > 16);
};

/**
 * Checks if the user is logged in.
 * @returns {boolean} - True if logged in, otherwise false.
 */
const loggedIn = () => {
    return getStoredUser() !== null;
};

/**
 * Logs out the user by clearing local storage.
 */
const logout = () => {
    localStorage.clear();
    accountAddress = null;
    wallet = initializeWalletAndContracts();
};

if(getStoredUser() !== null){
    console.log("User is logged in");
    // wallet = getOwnersWallet(await mainWallet());
}

export {
    wallet,
    accountAddress,
    mainWallet as getMainWallet,
    wallets as getWallets,
    threshold as getThreshold,
    owners as getOwners,
    addWallet,
    withdrawNFT,
    changeMainWallet,
    changeThreshold,
    removeOwner,
    removeWallet,
    createUser,
    importUser,
    validateUsername,
    loggedIn,
    logout
};

window.userAccount = {
    wallet,
    accountAddress,
    mainWallet,
    wallets,
    threshold,
    owners,
    addWallet,
    withdrawNFT,
    changeMainWallet,
    changeThreshold,
    removeOwner,
    removeWallet,
    createUser,
    importUser,
    validateUsername,
    loggedIn,
    logout
};