// strings.js

export const relayServer = process.env.REACT_APP_RELAY_SERVER_URL;
export const rpcUrl = process.env.REACT_APP_RPC_URL;

export const contractsManagerAddress = process.env.REACT_APP_CONTRACTS_MANAGER_ADDRESS;

export let userCreatorAddress = "";
export let levelsAddress = "";
export let xpAddress = "";
export let referralAddress = "";
export let followerManagerAddress = "";
export let blacxesAddress = "";
export let blacxesLockerAddress = "";
export let accesLockerAddress = "";
export let bioAddress = "";
export let oliAddress = "";
export let dataManagerAddress = "";
export let forwarderAddress = "";

// ABIs
export const contractsManagerABI = require('./../ABIs/ContractsManagerABI.json');
export const userAccountABI = require('./../ABIs/UserAccountABI.json');
export const accesLevelABI = require('./../ABIs/AccesLevelABI.json');
export const accesXPABI = require('./../ABIs/AccesXPABI.json');
export const referralABI = require('./../ABIs/ReferralABI.json');
export const userCreatorABI = require('./../ABIs/UserCreatorABI.json');
export const userBioABI = require('./../ABIs/UserBioABI.json');
export const followerManagerABI = require('./../ABIs/FollowerManagerABI.json');
export const balcxesABI = require('./../ABIs/BlacxesABI.json');
export const blacxesLockerABI = require('./../ABIs/BlacxesLockerABI.json');
export const accesLockerABI = require('./../ABIs/AccesLockerABI.json');
export const oliNFTABI = require('./../ABIs/OliNFTABI.json');
export const accesDataManagerABI = require('./../ABIs/AccesDataManagerABI.json');
export const forwarderABI = require('./../ABIs/ForwarderABI.json');

// Function to update addresses from ContractsManager
export const updateAddresses = (newAddresses) => {
    userCreatorAddress = newAddresses['CreatorProxy'];
    levelsAddress = newAddresses['AccesLevels'];
    xpAddress = newAddresses['AccesXp'];
    referralAddress = newAddresses['AccesReferral'];
    followerManagerAddress = newAddresses['FollowerManager'];
    blacxesAddress = newAddresses['Blacxes'];
    blacxesLockerAddress = newAddresses['BlacxesLocker'];
    accesLockerAddress = newAddresses['AccesCoinLocker'];
    bioAddress = newAddresses['UserBio'];
    oliAddress = newAddresses['OliNft'];
    dataManagerAddress = newAddresses['AccesDataManager'];
    forwarderAddress = newAddresses['Forwarder'];
};
