import { accountAddress } from "./account";
import { ethers, ZeroAddress } from "ethers";
import { contracts, provider } from "./contracts";
import { getOwnersWallet } from "./utils/contractUtils";
import { propose } from "./utils/transaction";
import { accesLockerAddress } from "./utils/strings";

/**
 * Fetches the total Acces balance for a given address.
 * @param {string} address - The address to get the total Acces for.
 * @returns {Promise<string>} - Formatted total Acces balance.
 */
const totalAcces = async (address = accountAddress) => {
    try {
        return parseFloat(ethers.formatUnits(await contracts.userAccount(address).totalAcces(), 18)) +
               parseFloat(await totalLockedAcces(address));
    } catch (e) {
        console.error("ERROR fetching total Acces:", e);
        return null;
    }
};

/**
 * Fetches the total locked Acces balance for a given address.
 * @param {string} address - The address to get the total locked Acces for.
 * @returns {Promise<string>} - Formatted total locked Acces balance.
 */
const totalLockedAcces = async (address = accountAddress) => {
    try {
        const totalLocked = await contracts.accesLocker.getTotalLockedForUser(address);
        return ethers.formatUnits(totalLocked, 18);
    } catch (e) {
        console.error("ERROR fetching total locked Acces:", e);
        return null;
    }
};

/**
 * Fetches the Acces balance of an address.
 * @param {string} address - The address to get the balance for.
 * @returns {Promise<string>} - Formatted Acces balance.
 */
const accessBalance = async (address = accountAddress) => {
    try {
        return ethers.formatUnits(await provider.getBalance(address), 18);
    } catch (e) {
        console.error("ERROR fetching Acces balance:", e);
        return null;
    }
};

/**
 * Sends Acces from one wallet to another.
 * @param {string|null} fromAddress - The sender's address (if null, defaults to accountAddress).
 * @param {string} toAddress - The recipient's address.
 * @param {string|number} amountInEther - The amount of Acces to send, in Ether.
 * @returns {Promise<object|null>} - Transaction result with status and transaction hash.
 */
const sendAcces = async (fromAddress, toAddress, amountInEther) => {
    try {
        if (!fromAddress || !toAddress || !amountInEther) return;

        if (fromAddress !== accountAddress) {
            try {
                let walletToSendFrom = getOwnersWallet(fromAddress).connect(provider);
                const amount = ethers.parseEther(amountInEther.toString());
                const tx = await walletToSendFrom.sendTransaction({
                    to: toAddress,
                    value: amount,
                });
                await tx.wait();
                console.log(`Sent ${amountInEther} Acces to ${toAddress}. Transaction hash: ${tx.hash}`);
                return { status: true, txHash: tx.hash };
            } catch (error) {
                console.error("Error sending Acces:", error);
                return { status: false, error: error.message };
            }
        } else {
            const amountInWei = ethers.parseEther(amountInEther.toString());
            try {
                const result = await propose(contracts.userAccount(accountAddress).interface.encodeFunctionData("withdrawAcces", [toAddress, amountInWei]));
                return result;
            } catch (error) {
                console.error("Error proposing transaction for withdrawAcces:", error);
                return { status: false, error: error.message };
            }
        }
    } catch (e) {
        console.error("ERROR in sendAcces:", e);
        return null;
    }
};

/**
 * Locks Acces tokens for a specified lock time.
 * @param {number} lockTime - The lock duration in seconds.
 * @param {string|number} amount - The amount of Acces to lock.
 * @returns {Promise<object|null>} - Transaction result with status and transaction hash.
 */
const lockCoins = async (lockTime, amount) => {
    try {
        const lockCoinsData = contracts.accesLocker.interface.encodeFunctionData("lockCoins", [lockTime]);
        return await propose(lockCoinsData, accesLockerAddress, ethers.parseEther(amount.toString()));
    } catch (e) {
        console.error("ERROR locking Acces:", e);
        return null;
    }
};

/**
 * Unlocks Acces tokens for a specific lock ID.
 * @param {number} lockId - The ID of the lock to unlock.
 * @returns {Promise<object|null>} - Transaction result with status and transaction hash.
 */
const unlockCoins = async (lockId) => {
    try {
        // Encode the function call for unlockCoins
        const unlockCoinsData = contracts.accesLocker.interface.encodeFunctionData("unlockCoins", [lockId]);

        console.log("Proposing transaction with extra gas...");
        const result = await propose(
            unlockCoinsData,    // Encoded data for unlockCoins
            accesLockerAddress, // Target address (AccesCoinLocker contract)
            0,                 // Value to send (0 for this case)
            0,                 // Operation type (0 for CALL)
            1000000n,           // Gas limit (increased from default)
            0n,                // Base gas
            0n,                // Gas price
            ZeroAddress,       // Gas token
            ZeroAddress        // Refund receiver
        );

        if (!result || !result.txHash) {
            throw new Error("Failed to propose the unlockCoins transaction.");
        }
        return { status: true, result };
    } catch (e) {
        console.error("ERROR proposing unlock transaction:", e);
        console.error("Error stack:", e.stack);
        return { status: false, error: e.message };
    }
};

/**
 * Fetches all locks for a specific user.
 * @param {string} user - The address of the user to fetch locks for.
 * @returns {Promise<object[]|null>} - An array of lock objects with details or null in case of an error.
 */
const getUserLocks = async (user = accountAddress) => {
    try {
        const locks = await contracts.accesLocker.getUserLocks(user);
        return locks.map((lock) => ({
            lockId: lock.lockId.toString(),
            amount: ethers.formatUnits(lock.amount, 18),
            lockTime: new Date(parseInt(lock.lockTime) * 1000).toISOString(), // Convert to readable date
            unlockTime: new Date(parseInt(lock.unlockTime) * 1000).toISOString(), // Convert to readable date
            isReleased: lock.isReleased,
        }));
    } catch (e) {
        console.error("ERROR fetching user locks:", e);
        return null;
    }
};

export {
    totalAcces as getTotalAcces,
    totalLockedAcces as getTotalLockedAcces,
    accessBalance as getAccesBalance,
    sendAcces,
    lockCoins as lockAcces,
    unlockCoins as unlockAcces,
    getUserLocks,
};

window.acces = {
    unlockCoins,
    getUserLocks,
    lockCoins,
    totalLockedAcces,
};
