import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { getUserTransactionsViaLogs } from '../ContractsInteraction/xpTransactions.js';
import { getUserProfile } from '../ContractsInteraction/profile.js';
import { accountAddressById } from '../ContractsInteraction/utils/contractUtils.js';
import { copyToClipboard, getShortString } from '../ContractsInteraction/utils/utils.js';
import { IconButton } from './IconButton.js';
import { faCopy, faExternalLink } from '@fortawesome/free-solid-svg-icons';

function ProfileModal({ userAddress, isOpen, onClose }) {
  const [transactions, setTransactions] = useState([]);
  const [isTransactionsOpen, setIsTransactionsOpen] = useState(false);
  const [accountAddress, setAccountAddress] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(false);
  const scanUrl = process.env.REACT_APP_SCAN_URL;

  useEffect(() => {
    if (userAddress) {
      const fetchAccountAddress = async () => {
        const address = await accountAddressById(userAddress);
        setAccountAddress(address);
      };
      fetchAccountAddress();
    }
  }, [userAddress]);

  useEffect(() => {
    if (accountAddress) {
      const retrieveProfile = async () => {
        const profile = await getUserProfile(accountAddress);
        setProfileData(profile);
      };
      retrieveProfile();
    }
  }, [accountAddress]);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const txs = await getUserTransactionsViaLogs(accountAddress);
      setTransactions(txs);
    } catch (err) {
      console.error('Error fetching transactions:', err);
    } finally {
      setLoading(false);
    }
  };

  const openTransactionsModal = async () => {
    setIsTransactionsOpen(true);
    await fetchTransactions();
  };

  if (!profileData) {
    return (
      <Modal title="Loading Profile" isOpen={isOpen} onClose={onClose} width='1000px'>
        Loading...
      </Modal>
    );
  }
  return (
    <Modal title={profileData.username} isOpen={isOpen} onClose={onClose} width='1000px'>
      <table className="profile-table">
        <tbody>
          <tr>
            <td className="label">UserId</td>
            <td className="value">
              <IconButton icon={faCopy} onClick={() => copyToClipboard(profileData.userId.toString())} />
              {getShortString(profileData.userId.toString(), 6, 8)}
            </td>
          </tr>
          <tr>
            <td className="label">User Account</td>
            <td className="value">
              <IconButton icon={faCopy} onClick={() => copyToClipboard(accountAddress)} />
              {getShortString(accountAddress, 6, 8)}
            </td>
          </tr>
          <tr>
            <td className="label">XP</td>
            <td className="value">{profileData.xp.toString()}</td>
          </tr>
          <tr>
            <td className="label">Referral XP</td>
            <td className="value">{profileData.refXp.toString()}</td>
          </tr>
          <tr>
            <td className="label">Level:</td>
            <td className="value">{profileData.level.toString()}</td>
          </tr>
          <tr>
            <td className="label">Badge:</td>
            <td className="value">{profileData.badge.toString()}</td>
          </tr>
        </tbody>
      </table>
      <button onClick={openTransactionsModal}>View XP Transactions</button>
      {
        isTransactionsOpen && (
          <Modal
            title="XP Transactions"
            isOpen={isTransactionsOpen}
            onClose={() => setIsTransactionsOpen(false)}
            width="700px"
          >
            {loading ? (
              <p>Retrieving transactions...</p>
            ) : transactions.length > 0 ? (
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                {/* XP Amounts Section */}
                <div
                  style={{
                    width: '80%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: '10px',
                  }}
                >
                  {/* Total Games Amount */}
                  <p style={{ fontWeight: 'bold', margin: 0, textAlign: 'center', flex: 1 }}>
                    Total Games: {transactions.length}
                  </p>
                  {/* XP Amount */}
                  <p style={{ fontWeight: 'bold', margin: 0, textAlign: 'center', flex: 1 }}>
                    XP: {transactions.reduce((total, tx) => total + tx.amount, 0)}
                  </p>
                  {/* Ref XP Amount */}
                  <p style={{ fontWeight: 'bold', margin: 0, textAlign: 'center', flex: 1 }}>
                    Referral XP: {profileData.refXp}
                  </p>
                  {/* Total XP Amount */}
                  <p style={{ fontWeight: 'bold', margin: 0, textAlign: 'center', flex: 1 }}>
                    Total XP: {transactions.reduce((total, tx) => total + tx.amount, 0) + profileData.refXp}
                  </p>
                </div>

                {/* Scrollable Table */}
                <div style={{ width: '100%', maxHeight: '600px', maxWidth: '1000px', overflowY: 'auto', overflowX: 'hidden' }}>
                  <table className="profile-table" style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Hash</th>
                        <th>Block</th>
                        <th>Amount</th>
                        {/* <th>Timestamp</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((tx, index) => (
                        <tr key={index}>
                          <td className='label'>{index + 1}</td>
                          <td>
                            <IconButton
                              icon={faExternalLink}
                              onClick={() => window.open(`${scanUrl}tx/${tx.hash}`, '_blank')}
                            />
                            {getShortString(tx.hash)}
                          </td>
                          <td>
                            <IconButton
                              icon={faExternalLink}
                              onClick={() => window.open(`${scanUrl}block/${tx.block}`, '_blank')}
                            />
                            {tx.block}
                          </td>
                          <td>{tx.amount}</td>
                          {/* <td>{new Date(tx.timestamp).toLocaleString()}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <p>No transactions found.</p>
            )}
          </Modal>

        )}
    </Modal>
  );
}

export default ProfileModal;
